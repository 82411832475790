<template>
    <div>
        <!-- modalAnimate -->
        <b-row>
            <b-col md="12">
                <b-form-group>
                    <span>Animations</span>
                    <b-form-checkbox name="modalAnimate" class="float-right" switch stacked v-model="selectedValueModalAnimate" />
                </b-form-group>
            </b-col>

            <!-- modalPlacement -->
            <b-col md="12">
                <b-form-group label="Placement" label-for="modalPlacement">
                    <Select2 name="modalPlacement" :options="modalPlacementOptions" v-model="selectedValues.modalPlacement" />
                </b-form-group>
            </b-col>

            <!-- modalDisplay -->
            <b-col md="12">
                <b-form-group label="When does it display?" label-for="modalDisplay">
                    <Select2 name="modalDisplay" :options="barDisplayOptions" v-model="selectedValues.modalDisplay" />
                </b-form-group>
            </b-col>

            <!-- animation_effect -->
            <b-col md="12">
                <b-form-group label="Animated Effect" label-for="animation_effect">
                    <b-form-select name="animation_effect" :options="animationEffectOptions" v-model="selectedValues.animation_effect" />
                </b-form-group>
            </b-col>
        </b-row>

    </div>
</template>

<script>
    import { BRow, BCol, BFormInput, BFormGroup, BFormCheckbox, BFormSelect } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import captureOptions from "@/libs/capture-Options"

    export default {
        directives: {
            Ripple
        },
        components: {
            BRow, BCol, BFormInput, BFormGroup, BFormCheckbox, BFormSelect,
        },
        name: 'ModalSetting',
        data() {
            return {}
        },
        props: {
            prop_siteSettingArray: {
                type: Object,
                default: () => { },
            }
        },
        setup() {
            const { modalPlacementOptions, barDisplayOptions, animationEffectOptions } = captureOptions();
            return {
                modalPlacementOptions, barDisplayOptions, animationEffectOptions
            }
        },
        computed: {
            /* GETS THE PROP VALUE INTO ANOTHER FUNCTION USING COMPUTED */
            selectedValues() {
                if (this.prop_siteSettingArray) {
                    return this.prop_siteSettingArray;
                } else {
                    return [];
                }
            },

            /* GETS THE modal animate VALUE USING THE selectedValues(). */
            selectedValueModalAnimate: {
                get() {
                    return this.selectedValues.modalAnimate == 1 ? true : false;
                },
                set(newValue) {
                    this.selectedValues.modalAnimate = newValue == true ? 1 : 0;
                }
            },
        },
        created() {
            if (_.isEmpty(this.prop_siteSettingArray.modalPlacement)) {
                this.prop_siteSettingArray.modalPlacement = 'top';
            }
            if (_.isEmpty(this.prop_siteSettingArray.modalDisplay) || this.prop_siteSettingArray.modalDisplay == 0) {
                this.prop_siteSettingArray.modalDisplay = '1';
            }
            if (_.isEmpty(this.prop_siteSettingArray.animation_effect)) {
                this.prop_siteSettingArray.animation_effect = 'null';
            }
        },
    }
</script>